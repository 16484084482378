export default {
  computed: {
    pctValorMercado() {
      const valorMercado = this.controlador.lote.valorMercado
      if (!valorMercado || !this.controlador.ultimoLance) {
        return null
      }
      return this.pct(valorMercado, this.controlador.ultimoLance.valor)
    },
    pctValorAvaliacao() {
      const valorAvaliacao = this.controlador.lote.valorAvaliacao
      if (!valorAvaliacao || !this.controlador.ultimoLance) {
        return null
      }
      return this.pct(valorAvaliacao, this.controlador.ultimoLance.valor)
    },
    pctValorMinimo() {
      const valorMinimo = this.controlador.lote.valorMinimo
      if (!valorMinimo || !this.controlador.ultimoLance) {
        return null
      }
      return this.pct(valorMinimo, this.controlador.ultimoLance.valor)
    }
  },
  methods: {
    pct(vi, vf) {
      let total = (vf / vi) * 100
      if (total >= 100) {
        return {
          type: 'positive',
          value: `+${Math.floor(total)}%`
        }
      } else {
        return {
          type: 'negative',
          value: `-${Math.floor(total)}%`
        }
      }
    }
  }
}