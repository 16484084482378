<template>
  <div class="leiloeiro-stats">
    <div class="column1 column-tabs">
      <div class="numero-lote">
        <div>LOTE {{ controlador.lote.numero }}</div>
        <span>{{ tentativasVenda }}º venda</span> <!-- @TODO -->
      </div>

      <div class="valor-atual" :class="{novoLance: hasNovoLance}">
        <div class="lbls">
          <div class="lbl">Lance atual</div>
          <div class="lbl">Incremento: <strong>R$ {{ lote.valorIncremento | moeda }}</strong></div>
        </div>
        <div v-if="controlador.ultimoLance" class="stats-input valor-atual val" :class="{novoLance: hasNovoLance}">R$
          {{ controlador.valorAtual | moeda }}
        </div>
        <div v-else class="stats-input valor-atual val">-</div>
      </div>

      <div class="c-arrematante">
        <div class="lbls">
          <div class="lbl">Arrematante</div>
        </div>
        <div v-if="controlador.ultimoLance" class="val">
          {{ controlador.ultimoLance.autor.apelido }}
          <span>{{ controlador.lanceLocalidade }}</span>
        </div>
        <div class="val" v-else>
          -
        </div>
      </div>
    </div>

    <div class="column2 column-tabs">
      <div class="comitente" v-if="lote.bem.comitente">
        <div v-if="lote.bem.comitente.image && lote.bem.comitente.image.thumb"><img :src="lote.bem.comitente.image.thumb"></div>
        <div v-else class="font-10 text-center">{{ lote.bem.comitente.pessoa.name }}</div>
      </div>

      <div class="valor-avaliacao">
        <div class="lbls">
          <div class="lbl">Valor de Avaliação</div>
          <div class="lbl lbl2" :class="pctValorAvaliacao.type" v-if="pctValorAvaliacao !== null">{{ pctValorAvaliacao.value }}</div>
          <div class="lbl" v-else>-</div>
        </div>
        <div class="val">R$ {{ lote.valorAvaliacao| moeda }}</div>
      </div>

      <div class="valor-minimo">
        <div class="lbls">
          <div class="lbl">Valor Mínimo</div>
          <div class="lbl lbl2" :class="pctValorMinimo.type" v-if="pctValorMinimo !== null">{{ pctValorMinimo.value }}</div>
          <div class="lbl" v-else>-</div>
        </div>
        <div class="val">R$ {{ lote.valorMinimo| moeda }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import pctMixin from "@/components/leiloes/components/tela-leiloeiro/mixin/pct-mixin"

export default {
  name: 'LeiloeiroStats',
  mixins: [pctMixin],
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false}
  },
  data() {
    return {
      hasNovoLance: false
    }
  },
  computed: {
    tentativasVenda() {
      const bem = this.controlador.lote.bem
      if (!Array.isArray(bem.lotes)) {
        return 1
      }
      if (bem.lotes.length === 1) {
        return 1
      }
      return bem.lotes.length
    }
  }
}
</script>
