<script>
import Controlador from '@/components/leiloes/components/controlador/ControladorV2'
import LeiloeiroStats from "./include/LeiloeiroStats"
import LeiloeiroLote from "@/components/leiloes/components/tela-leiloeiro/include/Lote"
import LeilaoTempoStats from "@/components/leiloes/components/tela-leiloeiro/include/LeilaoTempoStats"

export default {
  name: 'ControladorLeilao',
  mixins: [Controlador],
  components: {
    LeilaoTempoStats,
    LeiloeiroLote,
    LeiloeiroStats
  },
  data () {
    return {
      visibledKeyboard: false,
      visibledKeyboardStats: true
    }
  },
  computed: {},
  mounted () {
    this.page.setActive('leiloeiro')
    this.load()
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Tela do Leiloeiro',
    name: 'Tela do Leiloeiro'
  }
}
</script>

<template>
  <div v-if="!loading" class="controlador-v2 leiloeiro" :class="['show-keyboard']">
<!--    <stats-sup :leilao="leilao" />-->

    <div class="container-app">
      <screen-options :active-keyboard="visibledKeyboard" :active-resume="visibledKeyboardStats" @toggleKeyboard="showKeyboard" @toggleKeyboardStats="showKeyboardStats" />

      <leiloeiro-stats :leilao="leilao" :lote="lote" />

      <leiloeiro-lote :leilao="leilao" :lote="lote" />

      <div class="column-3">
        <div class="cronometro-container flex flex-center">
          <status :leilao="leilao" :lote="lote" mostrarAvisoLanceAutomatico />
        </div>
        <div class="leilao-stats">
          <leilao-tempo-stats :leilao="leilao" :lote="lote" />
        </div>
      </div>


      <keyboard
          ref="keyboard"
          :leilao="leilao"
          :lote="lote"
          :state="visibledKeyboard"
      />
      <estatisticas
          ref="keyboardStats"
          :leilao="leilao"
          :lote="lote"
          :state="visibledKeyboardStats"
          @estatisticas="updateEstatisticas"
      />
    </div>
  </div>
  <div class="controlador-v2 flex flex-center items-center column h-full no-select text-white"
       style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc />
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="../../assets/controlador-v2.styl" lang="stylus" />
