<template>
  <div class="leiloeiro-lote">
    <div class="lote-img-container">
      <div class="lote-img" v-if="lote.bem.image" :class="['lote-img-status' + lote.status]"
           :style="{backgroundImage: 'url('+lote.bem.image.min.url || lote.bem.image.url+')'}"></div>
      <div class="lote-img" v-else>
        <div class="noimg h-full w-full flex flex-center items-center justify-center column text-grey-6 no-select">
          <div>
            <u-icon name="image"/>
          </div>
          Sem imagem
        </div>
      </div>
    </div>

    <div class="lote-detalhes">
      <div class="titulo">
        {{
          String(lote.bem.siteTitulo).length > 130 ? String(lote.bem.siteTitulo).substring(0, 130) + '...' : String(lote.bem.siteTitulo)
        }}
      </div>
      <div v-if="showDescricao" class="descricao" :title="lote.bem.siteSubtitulo">
        {{
          String(lote.bem.siteSubtitulo).length > 130 ? String(lote.bem.siteSubtitulo).substring(0, 130) + '...' :
              String(lote.bem.siteSubtitulo)
        }}
      </div>

      <div class="info-avaliacao">
        <div class="lbl">
          AVALIAÇÃO SOBRE O LANCE ATUAL:
        </div>
        <div class="values">
          <div>
            <div class="title">Mercado/Fipe</div>
            <div class="val" :class="pctValorMercado.type" v-if="pctValorMercado !== null">{{ pctValorMercado.value }}</div>
            <div class="val" v-else>-</div>
          </div>
          <div>
            <div class="title">Avaliação</div>
            <div class="val" :class="pctValorAvaliacao.type" v-if="pctValorAvaliacao !== null">{{ pctValorAvaliacao.value }}</div>
            <div class="val" v-else>-</div>
          </div>
          <div>
            <div class="title">Mínimo</div>
            <div class="val" :class="pctValorMinimo.type" v-if="pctValorMinimo !== null">{{ pctValorMinimo.value }}</div>
            <div class="val" v-else>-</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pctMixin from '../mixin/pct-mixin'
export default {
  name: 'LeiloeiroLote',
  mixins: [pctMixin],
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false},
    showDescricao: {required: false, default: true}
  }
}
</script>
